<template>
  <div class="user">
    <PageNav title="我的易备" />
    <div class="user-wrap">
      <div class="header">
        <div class="user-info" v-if="this.userInfo">
          <img class="avatar" :src="this.userInfo.headImg" />
          <div class="info">
            <div class="name van-ellipsis">{{this.userInfo.nickName}}<span v-if="this.userInfo.isUnion == 1" class="union-tag">产业联盟</span></div>
            <div class="company">公司/组织：{{this.userInfo.company || '-'}}</div>
          </div>
        </div>
        <div class="settings" @click="onSetting">
          <van-icon name="setting-o" size="20" />
        </div>
        <div class="user-collect">
          <van-grid :column-num="2" :border="false">
            <van-grid-item icon="goods-collect-o" text="我的收藏" to="/user/collect" />
            <van-grid-item icon="eye-o" text="我的足迹" to="/user/history" />
          </van-grid>
        </div>
      </div>
      <div class="order-box">
        <div class="content">
          <van-grid>
            <van-grid-item icon="pending-payment" text="待付款" to="/order?tab=waitPay" />
            <van-grid-item icon="paid" text="待发货" to="/order?tab=waitSend" />
            <van-grid-item icon="logistics" text="待收货" to="/order?tab=waitConfirm" />
            <van-grid-item icon="idcard" text="全部订单" to="/order" />
          </van-grid>
        </div>
      </div>
      <div class="user-tool">
        <div class="title">工具与服务</div>
        <div class="container">
          <van-grid>
            <van-grid-item text="意向清单" to="/user/intention">
              <img style="width: 35px; height: 35px;" slot="icon" src="../assets/tool-1.jpg" />
            </van-grid-item>
            <van-grid-item text="我的问答" to="/user/askAnswer">
              <img style="width: 35px; height: 35px;" slot="icon" src="../assets/tool-2.jpg" />
            </van-grid-item>
            <van-grid-item text="我的租赁" to="/user/lease">
              <img style="width: 35px; height: 35px;" slot="icon" src="../assets/tool-3.jpg" />
            </van-grid-item>
            <van-grid-item text="我的采购" to="/user/buy">
              <img style="width: 35px; height: 35px;" slot="icon" src="../assets/tool-4.jpg" />
            </van-grid-item>
            <!-- <van-grid-item text="红包奖励" to="/user/wallet">
              <img style="width: 35px; height: 35px;" slot="icon" src="../assets/tool-5.jpg" />
            </van-grid-item> -->
          </van-grid>
        </div>
      </div>
      <div class="user-recommends">
        <div class="title">为您推荐</div>
        <van-list
          v-model="loading"
          :finished="finished"
          @load="fetchRecommendList"
        >
          <div class="prod-list">
            <div class="prod-item" v-for="item in recommendList" :key="item.productId" @click="onClickProd(item.productId)">
              <van-image
                lazy-load
                :src="item.titleImg"
              />
              <div class="prod-title van-multi-ellipsis--l2">{{ item.title }}</div>
              <div v-if="!!item.price && parseInt(item.price) != 0" class="price_pannel">
                <span>￥{{ item.price }}</span>
                <span>选购</span>
              </div>
              <div v-else class="price_pannel">
                <span>买方报价</span>
                <span>报价</span>
              </div>
            </div> 
          </div>
        </van-list>
      </div>
    </div>
    <div class="page-footer">
      <div class="footer-logo"><img src="../assets/headerLogo.png" /></div>
    </div>
    <TabBar :value="3" />
  </div>
</template>

<script>
import Vue from 'vue';
import User from '@/common/user';
import { Toast, Lazyload } from 'vant';
import Fetch from '@/common/fetch';
import { GetRecommandList, GetUserInfo } from '@/common/interface';
import TabBar from "../components/TabBar.vue";
import PageNav from '../components/PageNav.vue';

Vue.use(Toast);
Vue.use(Lazyload);

export default {
  name: "User",
  components: { TabBar, PageNav },
  data() {
    return {
      userInfo: null,
      curPage: 1,
      loading: false,
      finished: false,
      recommendList: [],
    };
  },
  created() {
    this.fetchUserInfo();
  },

  methods: {
    onBack() {
      this.$router.go(-1);
    },

    onSetting() {
      this.$router.push('/setting');
    },

    fetchUserInfo() {
      const user = User.getInfo() || {};
      const { uuid } = user;
      if (!uuid) {
        return;
      }
      const params = {
        uuid,
      };
      Fetch.post(GetUserInfo, params).then((res) => {
        this.userInfo = res;
        User.modifyInfo(res);
        window.scrollTo(0, 0);
      }).catch(() => {
      });
    },

    fetchRecommendList() {
      let page = this.curPage;
      const params = {
        pageNum: page,
        pageSize: 20,
      };
      
      Fetch.post(GetRecommandList, params).then(res => {
        const resList = res.list || [];
        const finished = resList.length < 20;
        const newList = this.recommendList.concat(resList);
        this.curPage = page + 1;
        this.recommendList = newList;
        
        this.loading = false;
        // 最多加载3页
        this.finished = page == 3 || finished;
      }).catch(err => {
        console.log(err);
      })
    },

    onClickProd(productId) {
      this.$router.push('/product?id=' + productId);
    },
  },
}
</script>

<style scoped>
body {
  background: #f2f2f2;
}
</style>

<style lang="scss">
.user-wrap {
  .header {
    position: relative;
    overflow: hidden;
    width: 100%;
    background: url(https://img12.360buyimg.com/img/s1500x886_jfs/t1/115726/22/13102/240974/5f18fb83Ee40e230b/d4f2a67087fd443a.png) no-repeat scroll 0 0/cover;
    box-sizing: border-box;
    padding: 18px 18px 15px;
    color: #fff;
    .user-info {
      display: flex;
      align-items: center;
      padding-bottom: 25px;
      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #fff;
      }
      .info {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding-left: 15px;
        height: 50px;
        overflow: hidden;
        .name {
          font-size: 18px;
          font-weight: bold;
          line-height: 1.2;
          .union-tag {
            margin-left: 10px;
            font-size: 10px;
            padding: 2px 4px;
            border-radius: 2px;
            color: gold;
            background: rgba(111,0,255,1);
          }
        }
        .company {
          font-size: 12px;
        }
      }
    }
    .user-collect {
      .van-grid-item__content {
        background: rgba($color: #000000, $alpha: 0);
      }
      .van-grid-item__text {
        color: #fff;
      }
    }
    .settings {
      position: absolute;
      right: 18px;
      top: 0;
      min-width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: right;
      padding-top: 8px;
    }
  }
  .order-box {
    position: relative;
    margin-top: -10px;
    margin-bottom: 15px;
    padding-top: 8px;
    line-height: 44px;
    background-color: #fff;
    border-radius: 12px;
    .content {
      height: 80px;
      padding: 0 14px;
    }
  }
  .user-tool {
    background: #fff;
    margin: 10px 0;
    border-radius: 15px;
    .title {
      position: relative;
      font-size: 14px;
      color: #2e2d2d;
      height: 38px;
      line-height: 38px;
      padding-left: 18px;
      font-weight: 700;
    }
    .container {
      padding-bottom: 10px;
      overflow: hidden;
    }
  }
  .van-grid {
    padding: 0;
  }
  .user-recommends {
    .title {
      position: relative;
      margin: 8px 0;
      height: 1em;
      line-height: 1em;
      text-align: center;
      font-size: 12px;
      color: #999;
    }
  }
}
</style>
